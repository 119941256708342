import i18n from "./"

export type UseTranslationReturn = {
  t: (key: string, params?: Record<string, any>) => string
}

export const useTranslation = (scope: string): UseTranslationReturn => {
  const t = (key: string, params?: Record<string, any>) => {
    return String(i18n.t(`${scope}.${key}`, params))
  }

  return { t }
}
